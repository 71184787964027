import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

export default function RadarChartGraph({data}) {
  
  // sort data by adjacencyPercentage, then filter list to only include top 15 adjacencies (prevents label overlap)
  data.sort((a, b) => (a.adjacencyPercentage > b.adjacencyPercentage) ? 1 : (a.adjacencyPercentage === b.adjacencyPercentage) ? ((a.size > b.size) ? 1 : -1) : -1 )
  const size = 12;
  data = data.slice(0, size);

  // sort data alphabetically
  data.sort((a, b) => (a.adjacentGroup > b.adjacentGroup) ? 1 : (a.adjacentGroup === b.adjacentGroup) ? ((a.size > b.size) ? 1 : -1) : -1 )

  const outerRadius = 60;
  const height = outerRadius*3 // 250;
  const width = outerRadius*4.75 // 400;

  return (
      <ResponsiveContainer>
        <small>
          <RadarChart
            cx={width/2}
            cy={height/2}
            outerRadius={60}
            width={width}
            height={height}
            data={data}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="adjacentGroup" />
            <PolarRadiusAxis />
            <Radar
              name="Adjacencies"
              dataKey="adjacencyPercentage"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
          </RadarChart>
        </small>
      </ResponsiveContainer>
  );
}