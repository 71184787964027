import React from "react";
import { useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import Loading from "../Loading";
import ErrorBoundary from "../ErrorBoundary";
import EntityHeaderCard from "../cards/EntityHeaderCard";
import RelatedEntitiesCard from "../cards/RelatedEntitiesCard";
import RelatedListCard from "../cards/RelatedListCard";
import CommentsCard from "../cards/CommentsCard";
import MapCard from "../cards/MapCard";
import ObjectAnnotations from "../cards/ObjectAnnotations";
import { Button } from "react-bootstrap";
import GeoJsonBuildingViewerCard from "../cards/GeoJsonBuildingViewerCard";

export default function BuildingScreen() {
  const app = useRealmApp();
  const params = useParams();

  var props = null;
  if (params.p) {
    props = {
      "objectId": "",
      "versionId": params.p,
      "globalId": params.id
    }
  } else {
    props = {
      "objectId": params.id,
      "versionId": "",
      "globalId": ""
    }
  }
  // console.log(props);

  return (
    <div>
      <ErrorBoundary>
        <ApiResults 
          app={app}
          props={props}
        />
      </ErrorBoundary>
    </div>
  );
}

function ApiResults({app, props}) {

  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getDocument(
      'entityBuilding',
      props.objectId,
      props.versionId,
      props.globalId
    )
    .then((response) => {
      // console.log("API Call - Building");
      setResponseData(response);
    })

  }, [props, app.currentUser.functions]);

  if (responseData) {
    return (
      <BuildingCardLayout 
        entity={responseData.results} 
      />
    );
  } else {
    return (
      <Loading />
    );
  }
}

function BuildingCardLayout ({entity}) {

  const [viewMode, setViewMode] = React.useState('map');

  // const boundaryObjects = getBoundaryObjectsList(entity);
  // const containsElements = getContainsElementsList(entity);
  // const entityProperties = {boundaryObjects, containsElements};

  var entityProperties = {};
  // if (Object.keys(entity).includes("isDecomposedBy")) {
  //   entityProperties["relatedEntities"] = getRelatedEntitiesList(entity);
  // }

  if (Object.keys(entity).includes("isDefinedBy")) {
    entityProperties["propertySets"] = getPropertySetList(entity);
  }

  const toggleMode = () => {
    setViewMode((oldMode) => (oldMode === "map" ? "model" : "map"));
    console.log("View Mode:", viewMode);
  };


  return (
    <div className="w-75 mx-auto">
      <div className="row">
        <EntityHeaderCard 
          parentEntityName={""}
          parentEntityLongName={""}
          entityName={entity.project.name} 
          entityLongName={entity.project.longName} 
        />
      </div>
      <div className="row">
        <div className="col-9">
          {viewMode === 'map' ? (<MapCard objectId={entity._id.toString()} />) : null }
          {viewMode === 'model' ? (<GeoJsonBuildingViewerCard objectId={entity._id.toString()} />) : null }
          <div className="row mt-2">
            <div className="col-3">
              <Button 
                className="w-100 my-1" 
                variant="outline-secondary"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMode();
                }}
              >View {viewMode === 'map' ? 'Model' : 'Map'}</Button>
              <RelatedListCard versionId={entity.project.versionId} relatedLists={[entity.isDecomposedBy]}/>
            </div>
            <div className="col-9">
              <RelatedEntitiesCard
                entityProperties={entityProperties}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <ObjectAnnotations objectId={entity._id.toString()} />
          <CommentsCard objectId={entity._id.toString()} collectionName='entityBuilding' />
        </div>
      </div>
    </div>
      
      
  );
}

function getPropertySetList(entity) {
  let propertySets = {};

  for (let i=0; i<entity.isDefinedBy.length; i++) {
    const propertySetName = entity.isDefinedBy[i].name;
    const properties = entity.isDefinedBy[i].properties;

    if (Object.keys(propertySets).includes(propertySetName)) { // Check if the property set already exists
        properties.forEach((property) => { // push each property to the list individually
          if (propertySets[propertySetName].includes(property)) { // Prevent duplicates - Shouldn't !propertySets[propertySetName].includes(property) work, not sure why this is working 
            propertySets[propertySetName].push(property);
          }
        });
        // propertySets[propertySetName].push(properties); // Properties is already a list, need to push each property individually
    } else {
        propertySets[propertySetName] = properties;
    }
  }
  return propertySets;
}