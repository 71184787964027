import React from 'react';
import { Card, Dropdown } from 'react-bootstrap'

export default function EntityTypeSelectorCard({entityType, setEntityType, propertiesToShow, setPropertiesToShow, propertyFilters, setPropertyFilters}) {
  return (
    <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Show Objects</Card.Header>
        Select an object type to show
        <EntitySelectionDropdown 
          entityType={entityType} 
          setEntityType={setEntityType} 
          propertiesToShow={propertiesToShow}
          setPropertiesToShow={setPropertiesToShow}
          propertyFilters={propertyFilters}
          setPropertyFilters={setPropertyFilters}
        />
    </Card>
  );
}

function EntitySelectionDropdown({entityType, setEntityType, propertiesToShow, setPropertiesToShow, propertyFilters, setPropertyFilters}) {

  const entityTypesList = [
    "Beam",
    "Column",
    "Covering",
    "CurtainWall",
    "DistributionPort",
    "Door",
    "DuctFitting",
    "DuctSegment",
    "ElementAssembly",
    "Fastener",
    "FireSuppressionTerminal",
    "FlowSegment",
    "FlowTerminal",
    "Footing",
    "Furniture",
    "LightFixture",
    "MechanicalFastener",
    "Member",
    // "OtherObjects", // When used as an argument in the realm function, this causes a memory exceeded limit error
    "PipeFitting",
    "PipeSegment",
    "Plate",
    "Railing",
    "Ramp",
    "ReinforcingBar",
    "ReinforcingMesh",
    "Roof",
    "Slab",
    "Space",
    "Stair",
    "StairFlight",
    "Tank",
    "Transformer",
    "Valve",
    "Wall",
    "Window",
  ];

  const formattedEntityType = entityType.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between every lowercase and uppercase letter (ex. aB -> a B)

  return (
  <Dropdown id="dropdown-item-button" title={formattedEntityType} className="">
      <Dropdown.Toggle className="mt-2 py-1 px-2 bg-white text-muted" variant="secondary">
        {formattedEntityType ? formattedEntityType : "Select an Object Type"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="">
        {entityTypesList.map((listItem, i) => (
          <Dropdown.Item 
            as="button" 
            key={i} 
            onClick={() => {
              setPropertiesToShow([]);
              setPropertyFilters([]);
              setEntityType(listItem);
            }}
            className="text-muted"
          >{listItem.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}