import React from 'react';
import { useRealmApp } from "../../RealmApp";
import { Badge, Button, Card, Form } from 'react-bootstrap';
import styled from "@emotion/styled";
import GridLoader from "react-spinners/GridLoader";

export default function PropertySelectorCard({entityType, propertiesToShow, setPropertiesToShow}) {
  const app = useRealmApp();

  const [isLoading, setIsLoading] = React.useState(false);
  const [propertiesList, setPropertiesList] = React.useState([]);
  const [selectedProperties, setSelectedProperties] = React.useState([]);

  React.useEffect(() => {
    if (entityType) {
      setIsLoading(true);
      setPropertiesToShow([]);
      setSelectedProperties([]);
      app.currentUser.functions.getEntityTypeProperties(entityType)
      .then((response) => {
        console.log(entityType);
        setPropertiesList(response);
        setIsLoading(false);
      });
    } else {
      console.log("No Object Type Selected")
    }
    
  }, [app.currentUser.functions, entityType]);

  if (isLoading) {
    return (
      <Card className="mt-3 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Show Properties</Card.Header>
        <Card.Body className="p-0">
        Select Properties To Show
        <div className="my-3">
          <GridLoader color="#D4D4D6" size="5px" margin="7px" />
        </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mt-3 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Show Properties</Card.Header>
        <Card.Body className="p-0">
        Select Properties To Show
        <ScrollArea>
        <PropertiesList 
          propertiesList={propertiesList} 
          selectedProperties={selectedProperties} 
          setSelectedProperties={setSelectedProperties} 
        />
        </ScrollArea>
        <Button
          size="sm"
          className="mt-2"
          onClick={(e) => {
            e.preventDefault();
            setPropertiesToShow(selectedProperties);
          }}
        >
          Update
        </Button>
        </Card.Body>
        
      </Card>
    </>
  );
}

function PropertiesList({propertiesList, selectedProperties, setSelectedProperties}) {

  return (
    <Form className="mx-2">
      {propertiesList.map((property, i) => (
        <Form.Row key={i}>
        <Form.Check 
          type="checkbox" 
          label={property.name} 
          checked={selectedProperties.includes(property.name)}
          onChange={(e) => {
            e.target.checked ? setSelectedProperties([...selectedProperties, property.name]) : 
            setSelectedProperties(
              selectedProperties.filter(
                function(value, index, arr) { 
                  return value !== property.name;
                }
              )
            )
          }}
        /><Badge className="mt-1" variant="light">{property.count}</Badge>
        </Form.Row>
      ))}
    </Form>
  );
}

const ScrollArea = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
`;