import React from 'react';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';

export default function DonutChart({data}) {
  // const COLORS = ['#D9B884', '#D9849F', '#8884D8', '#84D9C4', '#C5D984'];
  const [state, setState] = React.useState({activeIndex: 0})
  const width = 200;
  const height = width;
  const innerRadius = width*.35;
  const outerRadius = width*.42;

  const onPieEnter = (_, index) => {
    setState({
      activeIndex: index,
    });
    // state.activeIndex = index;
  };

  // Return gray segmented pie chart if no data
  if (data.length < 1) {
    data = [{name: "None", value: 1, annotationName: "None"}, {name: "None", value: 1, annotationName: "None"}, {name: "None", value: 1, annotationName: "None"}];
    
    return (
      <PieChart className="mx-auto" width={width} height={height}>
        <Pie
          // activeIndex={state.activeIndex}
          // activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#DCDCDC"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    )
  }

  data.sort((a, b) => (a.value - b.value));

  return (
    // <ResponsiveContainer width="100%" height="100%">
      <PieChart className="mx-auto" width={width} height={height}>
        <Pie
          activeIndex={state.activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#80C9D1"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    // </ResponsiveContainer>
  );
}

const renderActiveShape = (props) => {

  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={0} textAnchor="middle" fill={'#767676'}>
        {`${payload.name}`}
      </text>
      <text x={cx} y={cy} dy={18} textAnchor="middle" fill={'#767676'}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      <Link to={`/annotation/${payload.annotationName}/${payload.name}`}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={'#56A895'}
        />
      </Link>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 8}
        fill={'#56A895'}
      />
    </g>
  );
};

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}