import React from "react";
import GridLoader from "react-spinners/GridLoader";
import styled from "@emotion/styled";

export default function LoadingSmall() {
  return (
    <Container>
      <GridLoader color="#D4D4D6" size="10px" margin="10px" />
    </Container>
  )
}

const Container = styled.div`
  height: 480px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;