import React from 'react';
import { useRealmApp } from "../../RealmApp";
import { Button, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from "@emotion/styled";
import Loading from "../Loading";
import { ReactComponent as SortIcon } from "../../assets/img/arrow-down-up.svg";
import { ReactComponent as SortAscendingIcon } from "../../assets/img/arrow-up.svg";
import { ReactComponent as SortDescendingIcon } from "../../assets/img/arrow-down.svg";


export default function DataViewerCard({entityType, propertiesToShow, propertyFilters, reportData, setReportData}) {
  const app = useRealmApp();

  const [isLoading, setIsLoading] = React.useState(false);
  const [reportPage, setReportPage] = React.useState(0);
  const [pageRowLimit, setPageRowLimit] = React.useState(50);
  const [sortColumn, setSortColumn] = React.useState({
    "name": "",
    "order": "asc"
  });

  React.useEffect(() => {
    if (propertiesToShow.length > 0) {
      setIsLoading(true);
      setReportData([]);
      setReportPage(0);
      app.currentUser.functions.getEntityDataByTypeAndProperties(entityType, propertiesToShow, propertyFilters)
      .then((response) => {
        setReportData(response);
        console.log("Result Rows:", response.length);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setReportData("error");
        setIsLoading(false);
      });
    } else {
      setReportData([]);
      console.log("No Data To Show");
    }
  }, [entityType, propertiesToShow, propertyFilters]);

  // const columnNames = [
  //   "id", "name", ...propertiesToShow
  // ]

  if (isLoading) {
    return (
      <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Loading />
      </Card>
    );
  }

  if (reportData === "error") {
    return (
      <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <span className="mx-auto mt-5">We ran into a problem, try adjusting your properties and filters, or <Link to="/support">contact us for support</Link></span>
      </Card>
    );
  }

  if (reportData.length === 0 && propertiesToShow.length === 0) {
    return (
      <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <span className="mx-auto mt-5">Select an object type and properties to show data</span>
      </Card>
    );
  }

  if (reportData.length === 0 && propertiesToShow.length > 0) {
    return (
      <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <span className="mx-auto mt-5">
          We didn't find any data matching the properties and filters you selected
        </span>
      </Card>
    );
  }

  // Format the data returned from the database so that each row is formatted as an array instead of an unsorted array of objects
  const reportDataRows = getReportDataRows(propertiesToShow, reportData);

  return (
    <>
      <TableView
        propertiesToShow={propertiesToShow} 
        reportDataRows={reportDataRows}
        reportPage={reportPage}
        pageRowLimit={pageRowLimit}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
      />
      <PageNavigator 
        reportPage={reportPage}
        setReportPage={setReportPage}
        resultsCount={reportData.length}
        pageRowLimit={pageRowLimit}
      />
    </>
  );
}

function TableView({propertiesToShow, reportDataRows, reportPage, pageRowLimit, sortColumn, setSortColumn}) {

  const sortColumnIndex = propertiesToShow.indexOf(sortColumn.name);

  if (sortColumn.order === "asc") {
    reportDataRows.sort((a, b) => (a[sortColumnIndex] < b[sortColumnIndex]) ? 1 : (a[sortColumnIndex] === b[sortColumnIndex]) ? ((a.size > b.size) ? 1 : -1) : -1 );
  }

  if (sortColumn.order === "desc") {
    reportDataRows.sort((a, b) => (a[sortColumnIndex] > b[sortColumnIndex]) ? 1 : (a[sortColumnIndex] === b[sortColumnIndex]) ? ((a.size > b.size) ? 1 : -1) : -1 );
  }

  const tableData = reportDataRows.slice(reportPage*pageRowLimit, (reportPage+1)*pageRowLimit);

  return (
    <ScrollArea>
      <Table className="mt-1">
        <thead className="font-weight-bold">
          {tableData.length > 0 ? (
            <TableHeader 
              columnNames={propertiesToShow} 
              sortColumn={sortColumn}
              setSortColumn={setSortColumn}
            /> 
          ) : ""}
        </thead>
        <tbody>
        {tableData.map((row, i) => (
          <RowArray key={i} row={row} />
        ))}
        </tbody>
      </Table>
    </ScrollArea>
  );
}

function TableHeader({columnNames, sortColumn, setSortColumn}) {
  return (
    <tr>
      {columnNames.map((name, i) => (
        <TableHeaderCell>
          <SortColumnButton 
            columnName={name}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
          /> {name} 
        </TableHeaderCell>
      ))}
    </tr>
  );
}

function SortColumnButton({columnName, sortColumn, setSortColumn}) {
  if (columnName === sortColumn.name) {
    if (sortColumn.order === "asc") {
      return (
        <span className="mr-1" onClick={() => {setSortColumn({"name": sortColumn.name, "order": "desc"})}}>
          <SortAscendingIcon fill="black" />
        </span>
      )
    }

    if (sortColumn.order === "desc") {
      return (
        <span className="mr-1" onClick={() => {setSortColumn({"name": sortColumn.name, "order": "asc"})}}>
          <SortDescendingIcon fill="black" />
        </span>
      )
    }

    return (
      <span className="mr-1">
        <SortIcon fill="black" />
      </span>
    );
  }

  return (
    <span className="mr-1" onClick={() => {setSortColumn({"name": columnName, "order": sortColumn.order})}}>
      <SortIcon fill="lightgrey" />
    </span>
  );
}

function RowArray({row}) {
  return (
    <tr>
      {row.map((cell, i) => (
        <td key={i}>{cell}</td>
      ))}
    </tr>
  )
}

function PageNavigator({reportPage, setReportPage, resultsCount, pageRowLimit}) {

  const totalPages = Math.ceil(resultsCount / pageRowLimit);

  if (resultsCount === 0) {
    return "";
  }

  // Don't show the previous page button if the user is on the first results page
  // Don't show the next page button if the number of rows on the page is less than the limit variable (currently 100)
  return (
    <>
      {reportPage > 0 ? (
        <Button
          variant='light'
          onClick={() => {
            setReportPage(reportPage-1)
          }}
        >Previous</Button>
      ) : ""}
      <span className="mt-2 mx-2">Page {reportPage+1} of {totalPages}</span>
      {reportPage+1 < totalPages  ? (
        <Button
          variant="light"
          onClick={() => {
            setReportPage(reportPage+1)
          }}
        >Next</Button>
      ): ""}
    </>
  );
}

function getReportDataRows(columnNames, reportData) {
  let rowFormattedData = []

  reportData.forEach((rowData, i) => {

    let row = columnNames.map((column) => {
      return "";
    });

    rowData.columns.forEach((data, i) => {
      const columnIndex = columnNames.indexOf(data.name);
      if (typeof data.value !== 'string') {
        if (data.units) {
          row[columnIndex] = `${data.value.toString()} ${getUnitAbbreviation(data.units)}`
        } else {
          row[columnIndex] = data.value.toString();
        }
      } else {
        row[columnIndex] = data.value;
      } 
    });

    rowFormattedData.push(row);
  })

  return rowFormattedData;
}

function getUnitAbbreviation(unitString) {
  let unitAbbrevation = "";

  switch (unitString) {
    case "MILLIMETRE":
      unitAbbrevation = "mm";
      break;
    case "METRE":
        unitAbbrevation = "m";
        break;
    case "SQUARE_METRE":
      unitAbbrevation = "sq. m";
      break;
    case "FOOT":
      unitAbbrevation = "ft.";
      break;
    case "SQUARE FOOT":
        unitAbbrevation = "ft.";
        break;
    default:
      unitAbbrevation = "";
  }

  return unitAbbrevation;
}

const TableHeaderCell = styled.td`
  white-space: nowrap;
`;

const ScrollArea = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 2em;
`;