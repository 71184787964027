import React from "react";
import { useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import Loading from "../Loading";
import ErrorBoundary from "../ErrorBoundary";
import EntityHeaderCard from "../cards/EntityHeaderCard";
import RelatedEntitiesCard from "../cards/RelatedEntitiesCard";
import RelatedListCard from "../cards/RelatedListCard";
import CommentsCard from "../cards/CommentsCard";
import GeoJsonBuildingStoryViewerCard from "../cards/GeoJsonBuildingStoryViewerCard";

export default function BuildingStoryScreen() {
  const app = useRealmApp();
  const params = useParams();
  
  var props = null;
  if (params.p) {
    props = {
      "objectId": "",
      "versionId": params.p,
      "globalId": params.id
    }
  } else {
    props = {
      "objectId": params.id,
      "versionId": "",
      "globalId": ""
    }
  }

  return (
    <div>
      <ErrorBoundary>
        <ApiResults 
          app={app}
          props={props}
        />
      </ErrorBoundary>
    </div>
  );
}

function ApiResults({app, props}) {

  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getDocument(
      'entityBuildingStory',
      props.objectId,
      props.versionId,
      props.globalId
    )
    .then((response) => {
      console.log("API Call - BuildingStory");
      setResponseData(response);
    })
  }, [props, app.currentUser.functions]);

  if (responseData) {
    return (
      <BuildingStoreyCardLayout 
        entity={responseData.results} 
      />
    );
  } else {
    return (
      <Loading />
    );
  }
}

function BuildingStoreyCardLayout ({entity}) {

  const objectId = entity._id.toString();
  let containsElementTypes = []; // Array used to filter the dropdown in the GeoJson viewer


  // const boundaryObjects = getBoundaryObjectsList(entity);
  var entityProperties = {};
  // if (Object.keys(entity).includes("isDefinedBy")) {
  //   entityProperties["propertySets"] = getPropertySetList(entity);
  // }
  // if (Object.keys(entity).includes("decomposes" || "isDecomposedBy")) {
  //    entityProperties["relatedEntities"] = getRelatedEntitiesList(entity);
  // }

  if (Object.keys(entity).includes("isDecomposedBy")) {
    containsElementTypes.push("Space");
  }

  if (Object.keys(entity).includes("containsElements")) {
    entityProperties['containsElements'] = getContainsElementsList(entity);
    containsElementTypes.push(...Object.keys(entityProperties["containsElements"]));
    if (Object.keys(entityProperties['containsElements']).includes("FurnishingElement")) {
      // FurnishingElement is not used to get query furniture from the database
      // Need to add Furniture to the list in order to trigger it
      containsElementTypes.push("Furniture");
    }
    if (Object.keys(entityProperties['containsElements']).includes("WallStandardCase")) {
      // WallStandardCase is not used to get query furniture from the database
      // Need to add Wall to the list in order to trigger it
      containsElementTypes.push("Wall");
    }
  }

  var relatedLists = [];
  if (Object.keys(entity).includes("decomposes")) {
    const decomposes = [{
      "globalId": entity.decomposes.globalId,
      "type": entity.decomposes.type,
      "name": entity.project.name,
      "longName": entity.project.longName
    }];
    relatedLists.push(decomposes);
  }
  if (Object.keys(entity).includes("isDecomposedBy")) {
    relatedLists.push(entity.isDecomposedBy);
  }

  // become entity.decomposes only contains a type and globalId, we use the name and longname of the project to identify the building
  // The hierarchy of the IFC schema means that IfcBuildingStorey entities will always decompose a building

  // const decomposes = [{
  //   "globalId": entity.decomposes.globalId,
  //   "type": entity.decomposes.type,
  //   "name": entity.project.name,
  //   "longName": entity.project.longName
  // }];

  // const entityProperties = {relatedEntities, containsElements};
  return (
    <div className="w-75 mx-auto">
      <div className="row">
        <EntityHeaderCard 
          parentEntityName={entity.project.name}
          parentEntityLongName={entity.project.longName}
          entityName={entity.name} 
          entityLongName={entity.longName} 
        />
      </div>
      <div className="row">
          <div className="col-9">
            <div className="row">
              <div className="col-12">
                <GeoJsonBuildingStoryViewerCard 
                  objectId={objectId} 
                  containsElementTypes={containsElementTypes}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">
              <RelatedListCard versionId={entity.project.versionId} relatedLists={relatedLists} />
              </div>
              <div className="col-9">
                <RelatedEntitiesCard
                  entityProperties={entityProperties}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <CommentsCard objectId={entity._id.toString()} collectionName="entityBuildingStory" />
          </div>
        </div>
    </div>
  );
}

function getPropertySetList(entity) {
  let propertySets = {};

  for (let i=0; i<entity.isDefinedBy.length; i++) {
    const propertySetName = entity.isDefinedBy[i].name;
    const properties = entity.isDefinedBy[i].properties;

    if (Object.keys(propertySets).includes(propertySetName)) { // Check if the property set already exists
        properties.forEach((property) => { // push each property to the list individually
          if (propertySets[propertySetName].includes(property)) { // Prevent duplicates - Shouldn't !propertySets[propertySetName].includes(property) work, not sure why this is working 
            propertySets[propertySetName].push(property);
          }
        });
        // propertySets[propertySetName].push(properties); // Properties is already a list, need to push each property individually
    } else {
        propertySets[propertySetName] = properties;
    }
  }
  return propertySets;
}

function getContainsElementsList(entity) {
  let containsElements = {};
  // for building stories, the contains objects may either contain boundary or interior entities
  try {
    for (var i=0; i<entity.containsElements.length; i++) {
      const containsElementType = entity.containsElements[i].type;
      // const containsElementName = entity.containsElements[i].name; // Change this to name once the names have been added
      // console.log(containsElementType)
      // console.log(`${Object.keys(containsElements).includes(containsElementType)} | ${containsElementType} in ${Object.keys(containsElements)}`);
      if (Object.keys(containsElements).includes(containsElementType)) {
        if (containsElementType === "BuildingElementProxy" || containsElementType === "Furniture" || containsElementType === "FurnishingElement") {
          containsElements[containsElementType].push({
            "name": entity.containsElements[i].name, 
            "type": "entityInterior", 
            "versionId": entity.project.versionId,
            "globalId": entity.containsElements[i].globalId
          });
        } else {
          containsElements[containsElementType].push({
            "name": entity.containsElements[i].name, 
            "type": "entityBoundary", 
            "versionId": entity.project.versionId,
            "globalId": entity.containsElements[i].globalId
          });
        }
      } else {
        if (containsElementType === "BuildingElementProxy" || containsElementType === "Furniture" || containsElementType === "FurnishingElement") {
          containsElements[containsElementType] = [{
            "name": entity.containsElements[i].name, 
            "type": "entityInterior", 
            "versionId": entity.project.versionId,
            "globalId": entity.containsElements[i].globalId
          }];
        } else {
          containsElements[containsElementType] = [{
            "name": entity.containsElements[i].name, 
            "type": "entityBoundary", 
            "versionId": entity.project.versionId,
            "globalId": entity.containsElements[i].globalId
          }];
        }
      }
    }
  } catch {
    console.log("Interior elements not found");
  }
  // console.log(containsElements);
  return containsElements;
}

// function getRelatedEntitiesList(entity) {
//   let relatedEntities = {};
//   if (Object.keys(entity).includes("decomposes")) {
//     relatedEntities["Building"] = [{
//       "name": entity.project.longName, 
//       "type": "entityBuilding", 
//       "versionId": entity.project.versionId,
//       "globalId": entity.decomposes.globalId
//     }];
//   }

//   if (Object.keys(entity).includes("isDecomposedBy")){
//     for (var i=0; i<entity.isDecomposedBy.length; i++) {
//       const entityType = entity.isDecomposedBy[i].type;
//       const entityName = entity.isDecomposedBy[i].name; // Change this to name once the names have been added
//       // console.log(entityType)
//       // console.log(`${Object.keys(isDecomposedBy).includes(entityType)} | ${entityType} in ${Object.keys(isDecomposedBy)}`);
//       if (Object.keys(relatedEntities).includes(entityType)) {
//         relatedEntities[entityType].push({
//           "name": entity.isDecomposedBy[i].name,
//           "longName": entity.isDecomposedBy[i].longName, 
//           "type": "entitySpace", 
//           "versionId": entity.project.versionId,
//           "globalId": entity.isDecomposedBy[i].globalId
//         });
//       } else {
//         relatedEntities[entityType] = [{
//           "name": entity.isDecomposedBy[i].name,
//           "longName": entity.isDecomposedBy[i].longName, 
//           "type": "entitySpace", 
//           "versionId": entity.project.versionId,
//           "globalId": entity.isDecomposedBy[i].globalId
//         }];
//       }
//     }
//   }
//   return relatedEntities;
// }