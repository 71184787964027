import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CSVLink, CSVDownload } from "react-csv";
import EntityTypeSelectorCard from '../cards/EntityTypeSelectorCard';
import PropertySelectorCard from '../cards/PropertySelectorCard';
import PropertyFiltersCard from '../cards/PropertyFiltersCard';
import DataViewerCard from '../cards/DataViewerCard';

export default function ReportScreen() {

  const [entityType, setEntityType] = React.useState("");
  const [propertiesToShow, setPropertiesToShow] = React.useState([]);
  const [propertyFilters, setPropertyFilters] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);

  const csvData = downloadCSV(propertiesToShow, reportData);

  return (
    <>
      <Row className="mx-4 mt-4">
        <Col >
        <h2 className="float-left">Export Data</h2>
        <CSVLink 
          data={csvData}
          filename="precedent-export.csv"
        >
          <Button className="float-right" variant="outline-secondary">Download</Button>
        </CSVLink>;
        </Col>
      </Row>
      <Row className="mx-4 mb-3">
        <Col>
        Pull data from across your portfolio. Select an object type, the properties to include, then filter out any values to exclude.
        </Col>
      </Row>
      <Row className="mx-4 mt-3">
        <Col sm={3}>
          <EntityTypeSelectorCard 
            entityType={entityType} 
            setEntityType={setEntityType} 
            propertiesToShow={propertiesToShow}
            setPropertiesToShow={setPropertiesToShow}
            propertyFilters={propertyFilters}
            setPropertyFilters={setPropertyFilters}
          />
          <PropertySelectorCard entityType={entityType} propertiesToShow={propertiesToShow} setPropertiesToShow={setPropertiesToShow} />
          <PropertyFiltersCard entityType={entityType} propertiesToShow={propertiesToShow} propertyFilters={propertyFilters} setPropertyFilters={setPropertyFilters} />
        </Col>
        <Col sm={9}>
          <DataViewerCard 
            entityType={entityType} 
            propertiesToShow={propertiesToShow} 
            propertyFilters={propertyFilters} 
            reportData={reportData} 
            setReportData={setReportData} 
            />
        </Col>
      </Row>
    </>
  );
}

function downloadCSV(propertiesToShow, reportData) {
  const reportDataRows = getReportDataRows(propertiesToShow, reportData);
  return reportDataRows;
}

function getReportDataRows(columnNames, reportData) {
  let rowFormattedData = []

  rowFormattedData.push(columnNames);

  reportData.forEach((rowData, i) => {

    let row = columnNames.map((column) => {
      return "";
    });

    rowData.columns.forEach((data, i) => {
      const columnIndex = columnNames.indexOf(data.name);
      if (typeof data.value !== 'string') {
        if (data.units) {
          row[columnIndex] = `${data.value.toString()} ${getUnitAbbreviation(data.units)}`
        } else {
          row[columnIndex] = data.value.toString();
        }
      } else {
        row[columnIndex] = data.value;
      } 
    });

    rowFormattedData.push(row);
  })

  return rowFormattedData;
}

function getUnitAbbreviation(unitString) {
  let unitAbbrevation = "";

  switch (unitString) {
    case "MILLIMETRE":
      unitAbbrevation = "mm";
      break;
    case "METRE":
        unitAbbrevation = "m";
        break;
    case "SQUARE_METRE":
      unitAbbrevation = "sq. m";
      break;
    case "FOOT":
      unitAbbrevation = "ft.";
      break;
    case "SQUARE FOOT":
        unitAbbrevation = "ft.";
        break;
    default:
      unitAbbrevation = "";
  }

  return unitAbbrevation;
}