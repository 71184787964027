import React from "react";
import { useRealmApp } from "../../RealmApp";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import RadarChartCard from "../cards/RadarChartCard";
import StatsCardGroupTypeCount from "../cards/StatsCardGroupTypeCount";


export default function InsightsCard() {
  const app = useRealmApp();

  const [groupList, setGroupList] = React.useState("");
  const [currentGroup, setCurrentGroup] = React.useState("");

  React.useEffect(() => {
    // app.currentUser.functions.getGroupsList()
    app.currentUser.functions.getAnnotationDistinctText(
      'entitySpace',
      'NameTag'
    )    
    .then((response) => {
      setGroupList(response);
      const randomInt = getRandomInt(0, response.length);
      setCurrentGroup(response[randomInt]); // Set the current group to a random group so that the insights card is different every refresh
    })
  }, [app.currentUser.functions]);

  if (groupList && currentGroup) {

    let groupName = currentGroup.name;

    return (
      <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
        <Card.Header className="bg-white border-0 px-0 py-1">
          <h3 className="float-left">Space Insights</h3> 
          <GroupsDropdown
            groupList={groupList}
            currentGroup={currentGroup}
            setCurrentGroup={setCurrentGroup}
          />
        </Card.Header>
        <p>
          The collection of graphs below summarize key insights about circulation, adjacencies, 
          and common features of spaces found in Precedent. Click <Link to={`/group/${encodeURIComponent(groupName)}`} className="mx-auto mt-2">see more</Link> for 
          additional insights, and to see each of the underlying spaces that make up the category.
        </p>
        <div className="row">
          <div className="col-6 m-x-0">
            <RadarChartCard groupName={groupName} adjacencyDefinitionObject='Door' label='Shared Doors' />
          </div>
          <div className="col-6 mx-0">
            <RadarChartCard groupName={groupName} adjacencyDefinitionObject='Wall' label='Shared Walls' />
          </div>
        </div>
        <div className="row">
          <div className="col-6 m-x-0">
            <StatsCardGroupTypeCount groupName={groupName} objectType='Door' />
          </div>
          <div className="col-6 mx-0">
            <StatsCardGroupTypeCount groupName={groupName} objectType='Window' />
          </div>
        </div>
        <Link to={`/group/${encodeURIComponent(groupName)}`} className="mx-auto mt-2">See More</Link>
      </Card>
    );
  } else {
    return (
      <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
        <h3>Space Insights</h3>
        <p>
          Below is a collection of graphs summarizing key insights about circulation, adjacencies, 
          and common features of spaces found in Precedent.
        </p>
        <p className="text-center mt-3">
          You'll see insights after uploading a few BIM models<br />
          <Link to="/upload">Click here to upload files</Link>
        </p>
      </Card>
    );
  }
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function GroupsDropdown({groupList, currentGroup, setCurrentGroup}) {
  // Format the current group text
  const formattedCurrentGroupName = currentGroup.name.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every space

  return (
      <Dropdown id="dropdown-item-button" title={formattedCurrentGroupName} className="">
        <Dropdown.Toggle className="float-right p-0 bg-white text-dark border-0 text-muted">{formattedCurrentGroupName}</Dropdown.Toggle>
        <Dropdown.Menu className="">
          {groupList.map((group, i) => (
            <Dropdown.Item 
              as="button" 
              key={i} 
              onClick={() => setCurrentGroup(group)}
              className="text-muted"
            >{group.name.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();})}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
  );
}