import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function RelatedListCard({versionId, relatedLists}) {
  return (
    <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Related</Card.Header>
      <Card.Body className="px-0 py-0">
        {relatedLists.map((entityList, i) => (
          <RelatedList versionId={versionId} entityList={entityList} key={i} />
        ))}
      </Card.Body>
    </Card>
  );
}

function RelatedList({versionId, entityList, key}) {
  // console.log(entityList);
  return (
    <p key={key}>
      {entityList.map((entity, i) => (
        <RelatedEntity versionId={versionId} relatedEntity={entity} key={i} />
      ))}
    </p>
  );
}

function getScreen(entityType) {
  if (entityType === "Building") {
    return "building";
  }

  if (entityType === "BuildingStorey") {
    return "buildingstory";
  }

  if (entityType === "Space") {
    return "space";
  }
}

function RelatedEntity({
  versionId,
  relatedEntity,
  key
}) { 
  // console.log(relatedEntity);
  let relatedEntityName = "No Name"; // Default display if the entity doesn't have a name
  let relatedEntityLongName = "";
  const screen = getScreen(relatedEntity.type);
  const relatedEntityUrl = `/${screen}/${relatedEntity.globalId}/${versionId}`; // URL to the related entity page


  if (relatedEntity.name && relatedEntity.longName && relatedEntityName !== relatedEntity.longName) {
    // Remove spaces in between end of measurement and unit .replace(/([0-9]) ([a-z])/g, '$1$2')
    relatedEntityName = relatedEntity.name.replace(/[-|_|:]/g, ' ');
    relatedEntityLongName = relatedEntity.longName.replace(/[-|_|:]/g, ' ');
    if (relatedEntityName !== relatedEntityLongName) {
      return (
        <Link to={relatedEntityUrl} key={key}>
          {relatedEntityName} {relatedEntityLongName} <br />
        </Link>
      );
    } else {
      return (
        <Link to={relatedEntityUrl} key={key}>
          {relatedEntityName}<br />
        </Link>
      );
    }

  } else {
    if (relatedEntity.name) { // Removing the if statement will cause an error if relatedEntity.name == undefined which cannot use .replace()
      relatedEntityName = relatedEntity.name.replace(/[-|_|:]/g, ' ');
    } else {
      relatedEntityName = "No Name";
    }

    return (
      <Link to={relatedEntityUrl} key={key}>
        {relatedEntityName}<br />
      </Link>
    );
  }
}
