import { Vector3, MeshBuilder} from "@babylonjs/core";
import { VertexData, Mesh } from "@babylonjs/core";
import * as earcut from "earcut";
import * as math from "mathjs";
import { cross } from "mathjs";

export function handlePoint(geoJson, scene, material=null) {
  let coordinates = geoJson.geometry.coordinates;
  let marker = MeshBuilder.CreateSphere("sphere", {diameter: .15, segments: 32}, scene);
  marker.position.x = coordinates[0];
  marker.position.y = coordinates[2];
  marker.position.z = coordinates[1];
  marker.material = material;
}

export function handleLineString(geoJson, scene, material=null) {
  let coordinates = geoJson.geometry.coordinates;

  let lineStringPoints = [];

  coordinates.forEach((point) => {
    lineStringPoints.push(new Vector3(point[0], point[1], point[2]));
  })

  const options = {
    points: lineStringPoints //vec3 array,
  }

  let lines = MeshBuilder.CreateLines("lines", options, scene);  //scene is optional and defaults to the current scene
}

export function handleMultiPolygon(geoJson, scene, material=null) {
  let cubeTest = {
    "vertex": [],
    "face": []
  };

  let coordinates = geoJson.geometry.coordinates;
  coordinates.forEach((polygon, i) => {
    if (polygon[0].length <= 4 && polygon.length === 1) {
      let polygonVertexIndices = [];
      polygon[0].forEach((point, k) => {
        let babylonPoint = [point[0], point[2], point[1]]; // Reorder coordinates to coorespond with BabylonJS coordinate order xyz -> xzy (y is up)
        if (!cubeTest["vertex"].includes(babylonPoint)) {
            cubeTest["vertex"].push(babylonPoint);
        }
        const vertexIndex = cubeTest["vertex"].indexOf(babylonPoint);
        polygonVertexIndices.push(vertexIndex);
      });
      cubeTest["face"].push(polygonVertexIndices);
    } else {

      // let data = earcut.flatten(polygon);
      const triangulationResults = triangulatePolygon(polygon);
      const data = triangulationResults['data'];
      const triangles = triangulationResults['triangles'];

      const faceVertexIndices = new Array(Math.ceil(triangles.length / 3))
        .fill()
        .map(_ => triangles.splice(0, 3));
      const faceVerticies = new Array(Math.ceil(data.vertices.length / 3))
        .fill()
        .map(_ => data.vertices.splice(0, 3));

      // console.log("Face Vertex Indicies", faceVertexIndices);
      faceVertexIndices.forEach((face) => {
        let polygonVertexIndices = [];
        face.forEach((index) => {
          const point = faceVerticies[index];
          let babylonPoint = [point[0], point[2], point[1]]; // Reorder coordinates to coorespond with BabylonJS coordinate order xyz -> xzy (y is up)
          if (!cubeTest["vertex"].includes(babylonPoint)) {
            cubeTest["vertex"].push(babylonPoint);
          }
          const vertexIndex = cubeTest["vertex"].indexOf(babylonPoint);
          polygonVertexIndices.push(vertexIndex);
        });
        // console.log(polygonVertexIndices);
        cubeTest["face"].push(polygonVertexIndices);
      });
    }
  });

  var box = createPolyhedron(cubeTest, 1, scene);
    box.material = material;

  return scene;
}

function triangulatePolygon(polygon) {
  // Flatten polygon
  let data = {
    "vertices": [],
    "reorderedVerticies": [],
    "holes": [],
    "dimensions": 3
  }

  // Calculate the indices of vertices that are the first vertice in a hole
  let boundLengths = [];  
  polygon.forEach((bound, i) => {
    if (i < polygon.length-1) {
      if (bound[0] !== bound[bound.length-1]) {
        data.holes.push(boundLengths.reduce((a, b) => a + b, 0) + bound.length);
        boundLengths.push(bound.length);
      } else {
        data.holes.push(boundLengths.reduce((a, b) => a + b, 0) + bound.length-1);
        boundLengths.push(bound.length-1);
      }
    }
  });
  
  const vectorNormal = getNormalVector(polygon);  
  const rotationMatrix = rotationMatrixFromVectors(vectorNormal, new Vector3(0, 0, 1)); // Returns a MathJS

  polygon.forEach((boundary) => {
    boundary.forEach((point) => {
      data.vertices.push(point[0]);
      data.vertices.push(point[1]);
      data.vertices.push(point[2]);

      if (Number.isNaN(rotationMatrix._data[0][0])) {
        data.reorderedVerticies.push(point[0]);
        data.reorderedVerticies.push(point[1]);
        data.reorderedVerticies.push(point[2]);
      } else {
        const rotatedPoint = math.multiply(rotationMatrix, math.matrix(point))._data;
        // console.log("Rotated Point", rotatedPoint);
        data.reorderedVerticies.push(rotatedPoint[0]);
        data.reorderedVerticies.push(rotatedPoint[1]);
        data.reorderedVerticies.push(rotatedPoint[2]);
      }
    })
  });
  let triangles = earcut(data.reorderedVerticies, data.holes, data.dimensions);
  return {
    'data': data,
    'triangles': triangles
  };
}

function getNormalVector(polygon) {
  // Compute normal vector of the polygon by computing the cross product of the first three points
  // This assumes all points on the polygon are on the same plane
  // TODO: This needs to be cleaned up. Right now it mixes syntax from BabylonJS with MathJS and it's going to be impossible to maintain

  // Ensure that the points used to calculate the normal accurately represent the plane of the polygon.
  // The following code checks that consecutive points added to the normalPoints array do not fall along the same line
  // There is probably a more robust way to calculate this

  let normalPoints = [];

  // Get three distinct points on the polygon to calculate the normal vector of the polygon
  for (let i=0; i<polygon[0].length; i++) {
    if (normalPoints.length > 3) {
      break;
    } // only need 3 points to compute normal

    if (normalPoints.length === 0) {
      normalPoints.push(polygon[0][i]);
    } else {
      // Ensure that the three points 
      

      if (!normalPoints.includes(polygon[0][i])) {
        if (normalPoints.length === 2) {
          // ensure that the third point in the normal points list forms a triangle by checking to make sure the area of the resulting triangle is not 0
          const triangleArea = getTriangleArea(normalPoints[0], normalPoints[1], polygon[0][i]);
          if (triangleArea) {
            normalPoints.push(polygon[0][i]);
          }
        } else {
          normalPoints.push(polygon[0][i]);
        }
      }
    }
  }

  // Fallback if the previous loop does not produce 3 points
  if (normalPoints.length < 3) {
    normalPoints = polygon[0];
  }

  const vector1 = new Vector3(
    normalPoints[1][0], 
    normalPoints[1][1], 
    normalPoints[1][2]).subtract(
      new Vector3(
        normalPoints[0][0], 
        normalPoints[0][1], 
        normalPoints[0][2]
      )
    );

  const vector2 = new Vector3(
    normalPoints[2][0], 
    normalPoints[2][1], 
    normalPoints[2][2]).subtract(
      new Vector3(
        normalPoints[0][0], 
        normalPoints[0][1], 
        normalPoints[0][2]
      )
    );

  const vectorNormal = Vector3.Cross(vector1, vector2);
  vectorNormal.normalize() // Normalize vector for rotation

  return vectorNormal;
}

function createPolyhedron(data, size, scene) {
  var positions = [];
  var indices = [];
  var normals = [];
  var uvs = [];
  var face_uvs=[[0,0],[1,0],[1,1],[0,1]];

  // positions
  for (var i = 0; i < data.vertex.length; i++) {
    positions.push(data.vertex[i][0] * size, data.vertex[i][1] * size, data.vertex[i][2] * size);			  
  }

  // indices from faces		  
  for (var f = 0; f < data.face.length; f++) {
    for(var j = 0; j < data.face[f].length; j++) {
        uvs=uvs.concat(face_uvs[j]);
      }
    for (i = 0; i < data.face[f].length - 2; i++) {
      indices.push(data.face[f][0], data.face[f][i + 2], data.face[f][i + 1]);
    }
  }

  VertexData.ComputeNormals(positions, indices, normals);
  VertexData._ComputeSides(Mesh.FRONTSIDE, positions, indices, normals, uvs);

  var vertexData = new VertexData();
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  var polygon = new Mesh("Polygon", scene);
  vertexData.applyToMesh(polygon);

  return polygon;
};

function rotationMatrixFromVectors(vec1, vec2) {
  // Find the rotation matrix that aligns vec1 to vec2
  // Input vectors must be normalized
  // vec1: A 3d "source" vector
  // vec2: A 3d "destination" vector
  // return mat: A transform matrix (3x3) which when applied to vec1, aligns it with vec2.

  const v = Vector3.Cross(vec1, vec2).asArray();
  const c = Vector3.Dot(vec1, vec2);
  const s = Vector3.Cross(vec1, vec2).normalize().asArray();

  // Translates the following equation written in Python to Javascript
  // rotation_matrix = np.eye(3) + kmat + kmat.dot(kmat) * ((1 - c) / (s ** 2))

  const id = math.matrix([[1, 0, 0], [0, 1, 0], [0, 0, 1]]); // Identity matrix
  const kmat = math.matrix([[0, -v[2], v[1]], [v[2], 0, -v[0]], [-v[1], v[0], 0]]);
  const kmatMultiply = math.multiply(kmat, kmat);

  const d = math.multiply(kmatMultiply, math.divide(1-c, math.multiply(math.matrix(s), math.matrix(s))));
  const rotationMatrix = math.add(math.add(id, kmat), d);
  // console.log("(rotation matrix: ", rotationMatrix);

  return rotationMatrix
}

export function getTriangleArea(pt1, pt2, pt3) {

  const v1 = math.subtract(pt2, pt1);
  const v2 = math.subtract(pt3, pt1);

  const crossProduct = math.cross(v1, v2);

  try { // prevent errors if taking the sqrt of 0
    const vectorLength = math.sqrt(math.pow(crossProduct[0], 2) + math.pow(crossProduct[1], 2) + math.pow(crossProduct[2], 2))
    const area = vectorLength / 2;
    return area;
  } catch {
    return 0;
  }
}