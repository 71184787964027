import React from 'react';
import { useRealmApp } from "../../RealmApp";
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import styled from "@emotion/styled";
import GridLoader from "react-spinners/GridLoader";


export default function PropertyFiltersCard({entityType, propertiesToShow, propertyFilters, setPropertyFilters}) {
  const app = useRealmApp();

  const [isLoading, setIsLoading] = React.useState(false);
  const [propertyValues, setPropertyValues] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);

  React.useEffect(() => {
    if (entityType && propertiesToShow.length > 0) {
      setIsLoading(true);
      app.currentUser.functions.getEntityTypePropertyValues(entityType, propertiesToShow)
      .then((response) => {
        setPropertyValues(response)
        setIsLoading(false);
      });
    }
  }, [app.currentUser.functions, entityType, propertiesToShow]);

  if (isLoading) {
    return (
      <Card className="mt-3 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Filter Results</Card.Header>
        <Card.Body className="p-0">
        Select Properties To Show
        <div className="my-3">
          <GridLoader color="#D4D4D6" size="5px" margin="7px" />
        </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mt-3 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Filter Results</Card.Header>
     <Card.Body className="p-0">
       
       {propertyValues.map((property, i) => (
        <PropertyFilterCard 
          property={property} 
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          propertyFilters={propertyFilters}
          setPropertyFilters={setPropertyFilters}  
        />
       ))}
       <Button
          size="sm"
          className="mt-2"
          onClick={(e) => {
            e.preventDefault();
            setPropertyFilters(selectedFilters);
          }}
        >
          Update
        </Button>
     </Card.Body>
    </Card>
  );
}

function PropertyFilterCard({property, selectedFilters, setSelectedFilters, propertyFilters, setPropertyFilters}) {
  // Only list the filter if the type of the first value is a string or a boolean
  if (typeof property.values[0] === "number") {
    return ""; // Return an empty string if the first value is a number
  }

  if (selectedFilters.map((filter) => (filter.name)).includes(property.name)) {
    return(
      <Card className="my-2 pl-2 border-top-0 border-bottom-0 border-right-0 rounded-0">
        <div className="mt-1 mb-2">
          <span className="font-weight-bold float-left">{property.name}</span>
          <Button 
            size="sm" 
            variant="light" 
            className="py-0 float-right"
            onClick={(e) => {
              setSelectedFilters(
                selectedFilters.filter(
                  function(value, index, arr) { 
                    return value.name !== property.name;
                  }
                )
              )
            }}
          >On</Button>
        </div>
        <ScrollArea>
          <PropertyFilter property={property} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
        </ScrollArea>
      </Card>
      );
  } else {
    return (
      <Card className="my-2 pl-2 border-top-0 border-bottom-0 border-right-0 rounded-0">
        <div className="mt-1 mb-2">
          <span className="font-weight-bold float-left">{property.name}</span>
          <Button 
            size="sm" 
            variant="light" 
            className="py-0 float-right"
            onClick={(e) => {
              setSelectedFilters([...selectedFilters, property])
            }}
          >Off</Button>
        </div>
    </Card>
    );
  }
}

function PropertyFilter({property, selectedFilters, setSelectedFilters}) {
  if (typeof property.values[0] === "number") {
    // Do not show continuous filters initially. Different units attached to properties mean that
    // values are not equal without being converted to the same unit. Since Precedent doesn't attempt this when
    // exporting data, users can unintentionally filter out data they did not intend to filter.
    return "";
    // return (
    //   <ContinuousFilter values={property.values} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
    // );
  } else {
    return (
      <CategoricalFilter property={property} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
    );
  }
}

function CategoricalFilter({property, selectedFilters, setSelectedFilters}) {

  return (
    <>
      {property.values.map((value, i) => (
        <Form.Check 
          key={i}
          type="checkbox" 
          label={categoricalValue(value)} 
          checked={getCheckedValue(property.name, value, selectedFilters)}
          onChange={(e) => {
            setSelectedFilters(getNewFilters(e.target.checked, property.name, value, selectedFilters));
          }}
        />
      ))}
    </>
  );
}

function categoricalValue(value) {
  return value.toString();
}

function getCheckedValue(propertyName, value, selectedFilters) {
  let checked = false;

  selectedFilters.forEach((property) => {
    if (property.name === propertyName && property.values.includes(value)) {
      checked = true;
    }
  })

  return checked;
}

function getNewFilters(add, propertyName, value, selectedFilters) {
  // Add or remove a value from the selectedFilters list
  // remove: boolean indicated whether a value is to be added or removed from the filter
  // propertyName: name of the property with the value to be added/removed from filter
  // value: value to be added/removed from filter
  // selectedFilters: currently set filters
  // Returns a new list of properties and values to be passed to setSelectedFilters
  let newFilter = [];

  if (add) {
    // add the value to the matching property
    selectedFilters.forEach((property) => {
      if (property.name === propertyName) {
        const newValuesList = [...property.values, value];
        newFilter.push({
          'name': property.name,
          'values': newValuesList
        });
      } else {
        newFilter.push(property);
      }
    });
  } else {
    // remove the value from the matching property
    selectedFilters.forEach((property) => {
      if (property.name === propertyName && property.values.includes(value)) {
        const newValuesList = property.values.filter(
          function(v, index, arr) {
            return v !== value
          }
        )
        newFilter.push({
          'name': property.name,
          'values': newValuesList
        });
      } else {
        // if the property is not the one being modified, then push it to the new filter list as is
        newFilter.push(property);
      }
    });
  }

  return newFilter;
}

function ContinuousFilter({values, selectedFilters, setSelectedFilters}) {
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  return (
    <Form className="mx-3">
      <Row>
        <Col className="px-1">
        <Form.Group className="float-left">
          <Form.Label>Min</Form.Label>
          <Form.Control value={minValue}></Form.Control>
        </Form.Group>
        </Col>
        <Col className="px-1">
        <Form.Group className="float-right">
          <Form.Label>Max</Form.Label>
          <Form.Control value={maxValue}></Form.Control>
        </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

const ScrollArea = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
`;