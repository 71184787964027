import React from 'react';
import { useRealmApp } from "../../RealmApp";
import { Badge, Card, Dropdown } from "react-bootstrap";
import styled from "@emotion/styled";
import GridLoader from "react-spinners/GridLoader";

export default function TypeDefinitionFrequencyByAnnotationCard({annotationCollection, annotationName, annotationText}) {
  const app = useRealmApp();

  const [isLoading, setIsLoading] = React.useState(false);
  const [typeDefinitionGroups, setTypeDefinitionGroups] = React.useState("");
  const [currentTypeDefinitionGroup, setCurrentTypeDefinitionGroup] = React.useState("");
  const showTopN = 10;

  React.useEffect(() => {
    setIsLoading(true);
    app.currentUser.functions.getTypeDefinitionFrequencyByAnnotationText(annotationCollection, annotationName, annotationText)
    .then((response) => {
      setTypeDefinitionGroups(response);
      const randomInt = getRandomInt(0, response.length);
      setCurrentTypeDefinitionGroup(response[randomInt]); // Set the current group to a random group so that the insights card is different every refresh
      setIsLoading(false);
    })
  }, [])

  if (isLoading) {
    return (
      <Card className="mt-3 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Related Families</Card.Header>
        <Card.Body className="p-0">
        <div className="my-3">
          <GridLoader color="#D4D4D6" size="5px" margin="7px" />
        </div>
        </Card.Body>
      </Card>
    );
  }

  if (typeDefinitionGroups && currentTypeDefinitionGroup) {

    let typeDefinitionsToShow = [];

    for (let i=0;i<showTopN;i++) {
      typeDefinitionsToShow.push(
        currentTypeDefinitionGroup.typeDefinitions[i]
      );
    }

    return (
      <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
          <span className="float-left">Related Families</span><br />
          <GroupsDropdown
            typeDefinitionGroups={typeDefinitionGroups}
            currentTypeDefinitionGroup={currentTypeDefinitionGroup}
            setCurrentTypeDefinitionGroup={setCurrentTypeDefinitionGroup}
          />
        </Card.Header>
        {/* <Card.Header className="bg-white border-0 px-0 py-1">
          <h3 className="float-left">Family Use Insights</h3> 
          
        </Card.Header> */}
        <p>
        Shows the top {showTopN} families related to the spaces in this group.
        </p>
        <ScrollArea>
        {typeDefinitionsToShow.map((typeDefinition) => (
          <TypeDefinition typeDefinition={typeDefinition} />
        ))}
        </ScrollArea>
        
      </Card>
    );
  }

  return (
    <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
         <span className="float-left">Related Families</span>
      </Card.Header>
      <p>
        We did not find any families associated with the spaces in this group.
      </p>      
    </Card>
  );
}

function GroupsDropdown({typeDefinitionGroups, currentTypeDefinitionGroup, setCurrentTypeDefinitionGroup}) {
  // Format the current group text
  const formattedCurrentGroupName = currentTypeDefinitionGroup._id.replace(/([a-z])([A-Z])/g, '$1 $2').trim() // Capitalize the first letter after every space

  return (
    <Dropdown id="dropdown-item-button" title={formattedCurrentGroupName} className="">
      <Dropdown.Toggle className="p-0 bg-white text-dark border-0 text-muted">{formattedCurrentGroupName}</Dropdown.Toggle>
      <Dropdown.Menu className="">
        {typeDefinitionGroups.map((group, i) => (
          <Dropdown.Item 
            as="button" 
            key={i} 
            onClick={() => setCurrentTypeDefinitionGroup(group)}
            className="text-muted"
          >{group._id.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TypeDefinition({typeDefinition}) {
  if (typeDefinition) {
    return (
      <Card className="border-left-0 border-right-0 border-bottom-0 rounded-0 mb-2">
        <Card.Body className="p-2">
          <b>{typeDefinition.name}</b><br />
        <Badge pill variant="primary">{typeDefinition.frequency}</Badge> Instances 
        <Badge pill variant="light" className="ml-4">{typeDefinition.projects}</Badge> Projects
        </Card.Body>
      </Card>
    )
  }

  return "";
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const ScrollArea = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;