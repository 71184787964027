import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import styled from "@emotion/styled";

export default function RelatedEntitiesCard({
  entityProperties
}) {
  let categories = Object.keys(entityProperties);
  let propertySets = {};
  // console.log(entityProperties);
  for (var i=0;i<categories.length;i++) {
    let category = categories[i];
    let catPropertySets = Object.keys(entityProperties[categories[i]]);
    propertySets[category] = [];
    for (var k=0;k<catPropertySets.length;k++) {
      propertySets[category].push(catPropertySets[k]);
    }
  }

  const [currentCategory, setCurrentCategory] = useState(categories[0]);
  // const [visiblePropertySets, setVisiblePropertySets] = useState(true);

  if (categories && propertySets && currentCategory) {
    return (
      <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
        <PropertiesCardHeader
          categories={categories}
          propertySets={propertySets}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
        />
        <VisiblePropertySets 
          currentCategory={currentCategory}
          // visiblePropertySets={visiblePropertySets}
          entityProperties={entityProperties}
        />
      </Card>
    );
  } else {
    return (
      <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
        We couldn't find any properties in the model
      </Card>
    );
  }
}

function PropertiesCardHeader({
  categories, 
  propertySets,
  currentCategory,
  setCurrentCategory
}) {
  return (
    <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
      <CategoriesDropdown className="float-left p-0 bg-white text-dark border-0" currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} items={categories}/>
      {/* <HeaderDropdown className="float-right p-0 bg-white text-dark border-0" dropdownTitle={"Property Sets"} items={propertySets[currentCategory]}/> */}
    </Card.Header>
  );
}

function CategoriesDropdown({className, currentCategory, setCurrentCategory, items}) {
  // Format the current category text
  currentCategory = currentCategory.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between lowercase and uppercase letters
  currentCategory = currentCategory.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every space

  // Format the text for the different categories
  var itemsDisplayText = []
  for (let i=0; i<items.length; i++) {
    let displayText = items[i];
    displayText = displayText.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between lowercase and uppercase letters
    displayText = displayText.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every space
    itemsDisplayText.push(displayText);
  }

  return (
      <Dropdown id="dropdown-item-button" title={currentCategory} className={className}>
        <Dropdown.Toggle className={className}><h4 className={className}>{currentCategory}</h4></Dropdown.Toggle>
        <Dropdown.Menu>
          {itemsDisplayText.map((displayText, i) => (
            <Dropdown.Item as="button" key={i} onClick={() => setCurrentCategory(items[i])}>{displayText}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
  );
}

// function HeaderDropdown({className, dropdownTitle, items}) {
//   return (
//     <Dropdown id="dropdown-item-button" title={dropdownTitle} className={className}>
//       <Dropdown.Toggle className={className}><h3 className={className}>{dropdownTitle}</h3></Dropdown.Toggle>
//       <Dropdown.Menu>
//         {items.map((item, i) => (
//           <Dropdown.Item as="button" key={i}>{item}</Dropdown.Item>
//         ))}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

function VisiblePropertySets({
  currentCategory,
  setCurrentCategory,
  visiblePropertySets,
  entityProperties
}) {
  const currentCategoryPropertySets = entityProperties[currentCategory];
  
  return (
    <Card.Body className="px-0">
      {Object.entries(currentCategoryPropertySets).map((entry, i) => (
        <PropertySetContainerCards
          key={i}
          propertySetName={entry[0]} 
          propertiesList={entry[1]} />
      ))}
    </Card.Body>
  );
}

function PropertySetContainerCards({
  key,
  propertySetName,
  propertiesList
}) {
  if (propertySetName && propertiesList) {
    return (
      <div key={key}>
        <PropertySetHeader
          key={key}
          propertySetName={propertySetName}
          propertySetLength={propertiesList.length}
        />
        <PropertySetCardDeck
          key={key}
          propertiesList={propertiesList}
        />
      </div>
    );
  } else {
    return null;
  }
}

function PropertySetCardDeck({
  key,
  propertiesList
}) {
  return (
    <CardDeckGrid key={key}>
      {propertiesList.map((property, i) => (
        <Card className="p-3 rounded-0 border-top-0 border-bottom-0 border-right-0" key={i}>
          <PropertyDisplayCardFormat
            key={i}
            property={property}
          />
        </Card>
      ))}
    </CardDeckGrid>
  );
}

function PropertySetHeader({key, propertySetName, propertySetLength}) {
  let  formattedName = propertySetName.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  formattedName = formattedName.replaceAll("_", " ");
  return (
    <div className="my-4">
    <h5><Badge className="float-left mr-2" variant="light">{propertySetLength}</Badge></h5><h5>{formattedName}</h5>
    </div>
  );
}

function PropertyDisplayCardFormat({property, i}) {
  if (Object.keys(property).includes("value") || Object.keys(property).includes("thickness")) {
    return (
      <PropertyCardFormat 
        key={i}
        property={property} 
      />
    );
  } else {
    return (
      <RelatedEntity 
        key={i}
        relatedEntity={property} 
      />
    );
  }
}

function PropertyCardFormat({
  property,
  units=""
}) {
  let propertyName = property.name.replace(/([a-z])([A-Z])/g, '$1 $2').trim()
  let propertyUnits = null;
  let propertyValue = null;

  if (property.units) {
    propertyUnits = property.units.toLowerCase();
  }

  if (property.thickness) {
    propertyValue = Object.values(property.thickness);
    propertyValue = Math.round(propertyValue * 100) / 100
  } else if (typeof property.value === "object") {
    propertyValue = Object.values(property.value);
    propertyValue = Math.round(propertyValue * 100) / 100
  } else {
    propertyValue = property.value.toString().replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  }

  return (
    <div className="text-left text-middle">
      <small><b>{propertyName}</b> <span className="text-muted">{propertyUnits}</span></small>
      <h4 className="text-muted">{propertyValue}</h4>
    </div>
  );
}

function RelatedEntity({
  relatedEntity
}) { 
  let relatedEntityName = "No Name"; // Default display if the entity doesn't have a name
  let relatedEntityLongName = "";
  const relatedEntityCollectionName = relatedEntity.type.replace("entity", "").toLowerCase(); // remove entity prefix (entitySpace -> space)
  const relatedEntityUrl = `/${relatedEntityCollectionName}/${relatedEntity.globalId}/${relatedEntity.versionId}`; // URL to the related entity page

  if (!relatedEntity) {
    return (
      <>
        {relatedEntityName}<br />
        {relatedEntityLongName}<br />
      </>
    );
  }

  if (relatedEntity.name && relatedEntity.longName) {
    // Remove spaces in between end of measurement and unit .replace(/([0-9]) ([a-z])/g, '$1$2')
    relatedEntityName = relatedEntity.name.replace(/[-|_|:]/g, ' ');
    relatedEntityLongName = relatedEntity.longName.replace(/[-|_|:]/g, ' ');
    if (!relatedEntity.globalId) {
      return (
        <div>
          {relatedEntityName}<br />
          {relatedEntityLongName}<br />
        </div>
      );
    }

    return (
      <div>
        <Link to={relatedEntityUrl}>
          {relatedEntityName}<br />
          {relatedEntityLongName}<br />
        </Link>
      </div>
    );

  } else {
    if (relatedEntity.name) { // Removing the if statement will cause an error if relatedEntity.name == undefined which cannot use .replace()
      relatedEntityName = relatedEntity.name.replace(/[-|_|:]/g, ' ');
    } else {
      
    }

    if (!relatedEntity.globalId) {
      return (
        <div>
          {relatedEntityName}<br />
        </div>
      );
    }

    return (
      <div>
        <Link to={relatedEntityUrl}>
          {relatedEntityName}<br />
        </Link>
      </div>
    );
  }

  // const relatedEntityLink = relatedEntityLink.replace(/[-|_|:]/g, ' ');
  // // Format the collection name to the link format 
  // const relatedEntityCollectionLink = relatedEntity.type.replace("entity", "").toLowerCase(); 
  
}

const CardDeckGrid = styled.div`
  margin-top: 10px;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: .5rem;
`;