import React from "react";
import { useRealmApp } from "../../RealmApp";
import AnnotationCard from "../cards/AnnotationCard";

export default function ObjectAnnotations({objectId}) {
  const app = useRealmApp();
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getObjectAnnotations(objectId)
    .then((response) => {
      setResponseData(response);
    })
  }, [objectId, app.currentUser.functions]);

  if (responseData) {
    return (
      responseData.map((annotation, i) => (
        <AnnotationCard annotationDocument={annotation} key={i} />
      ))
    );
  } else {
    return null;
  }
  
}
