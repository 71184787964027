import React, { useState} from 'react';
import Card from 'react-bootstrap/Card';
import { useRealmApp } from "../../RealmApp";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from 'react-bootstrap';
import BuildingRender from '../viewer/BuildingRender';
import LoadingSmall from '../LoadingSmall';
import Legend from '../viewer/Legend';

export default function GeoJsonViewerCard({objectId, containsElementTypes=[]}) {
  const app = useRealmApp();

  const [modelSettings, setModelSettings] = React.useState({
    "type": "Space",
    "visPropertyType": "annotations",
    "visProperty": "NameTag"
  });

  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    setResponseData("");
    app.currentUser.functions.getFeatureCollectionBuilding(objectId, modelSettings.type, 400)
    .then((response) => {
      setResponseData(response);
    })
    .catch((error) => {
      setResponseData([])
    })
  }, [objectId, modelSettings.type, app.currentUser.functions]);

  if (responseData) {
    let visualizationList = {"annotations": [], "model": []};
    if (!responseData[0]) {
      return (
        <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
          <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
            Model
            <ToggleButtonExample 
              visualizationList={visualizationList}
              containsElementTypes={containsElementTypes}
              modelSettings={modelSettings}
              setModelSettings={setModelSettings}
            />
          </Card.Header>
          <Card.Body>
            There are too many objects in the model to display.
            <br />
            Try looking at one of the building stories to show a see a portion of the {modelSettings.type.toLowerCase()}s
          </Card.Body>
        </Card>
      );
    }

    if (responseData[0]['containsElementTypes']) {
      containsElementTypes = responseData[0]['containsElementTypes'];
    }

    responseData[0].features.forEach((feature) => {
      if (feature.properties.visualization.annotations) {
        feature.properties.visualization.annotations.forEach((property) => {
          if (!visualizationList.annotations.includes(property.name)) {
            visualizationList.annotations.push(property.name);
          }
        });
      }
      if (feature.properties.visualization.model) {
        feature.properties.visualization.model.forEach((property) => {
          if (!visualizationList.model.includes(property.name)) {
            visualizationList.model.push(property.name);
          }
        });
      }
    });

    const visPropertyValues = getVisualizationPropertyRange({'geoJson': responseData[0], 'modelSettings': modelSettings});
    // console.log("Model Settings:", modelSettings);
    return (
      <Card 
        className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0"
      >
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
        <ToggleButtonExample 
          visualizationList={visualizationList}
          containsElementTypes={containsElementTypes}
          modelSettings={modelSettings}
          setModelSettings={setModelSettings}
        />
      </Card.Header>
        <BuildingRender
          geoJson={responseData[0]}
          modelSettings={modelSettings}
          visPropertyValues={visPropertyValues}
        />
        <Legend visPropertyValues={visPropertyValues} />
      </Card>
    );
  } else {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
      </Card.Header>
      <LoadingSmall />
      </Card>
    );
  }
}

function ToggleButtonExample({visualizationList, containsElementTypes, modelSettings, setModelSettings}) {
  
  const includeElementTypes = ["Covering", "Door", "Slab", "Wall", "Window", "Column", "LightFixture", "Furniture", "BuildingElementProxy"];
  const displayOptions = getDisplayOptions(containsElementTypes, includeElementTypes);

  // sort data alphabetically
  visualizationList.annotations.sort();
  visualizationList.model.sort();

  return (
    <ButtonGroup className="float-right">
      <DropdownButton
        className="border-0"
        size="sm"
        variant="light"
        title={modelSettings.type.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
      >
        {displayOptions.map((group) => (
          <>
            <Dropdown.Header className="text-muted">{group.header}</Dropdown.Header>
            <DisplayItems 
              displayItems={group.items} 
              containsElementTypes={containsElementTypes}
              modelSettings={modelSettings} 
              setModelSettings={setModelSettings} 
            />
          </>
        ))}
      </DropdownButton>
      <DropdownButton
        className="border-0"
        size="sm"
        variant="light"
        title={modelSettings.visProperty.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
      >
      <Dropdown.Header className="text-muted">Annotations</Dropdown.Header>
        {visualizationList.annotations.map((propertyName, i) => (
        <Dropdown.Item
          variant="light"
          key={i}
          value={propertyName}
          checked={modelSettings.visProperty === propertyName}
          onClick={(e) => setModelSettings({...modelSettings, "visPropertyType": "annotations", "visProperty": propertyName})}
        >{propertyName.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</Dropdown.Item>
      ))}
      <Dropdown.Header className="text-muted">Model Properties</Dropdown.Header>
      {visualizationList.model.map((propertyName, i) => (
        <Dropdown.Item 
          variant="light" 
          key={i}
          value={propertyName}
          checked={modelSettings.visProperty === propertyName}
          onClick={(e) => setModelSettings({...modelSettings, "visPropertyType": "model", "visProperty": propertyName})}
        >{propertyName.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</Dropdown.Item>
      ))}
      </DropdownButton>
    </ButtonGroup>
  );
}

function getDisplayOptions(containsElementTypes, includeElementTypes) {
  let displayOptions = [];
  if (containsElementTypes.includes("Space")) {
    displayOptions.push(
      {"header": "Spaces",
        "items": [
          {"label": "Spaces", "settings": {"type": "Space", "related": false}}
        ]
      }
    );
  }

  let objectsList = [];

  if (containsElementTypes.includes("Wall") || containsElementTypes.includes("WallStandardCase")) {
    objectsList.push(
      {"label": "Walls", "settings": {"type": "Wall"}, "related": false}
    );
  }

  if (containsElementTypes.includes("BuildingElementProxy")) {
    objectsList.push(
      {"label": "Other Objects", "settings": {"type": "BuildingElementProxy", "related": false}}
    );
  }

  containsElementTypes.forEach((elementType) => {
    if (!["Space", "Wall", "WallStandardCase", "BuildingElementProxy"].includes(elementType)) { //&& includeElementTypes.includes(elementType)) {
      const label = elementType.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every element
      objectsList.push(
        {"label": label, "settings": {"type": elementType, "related": false}}
      );
    }
  });

  if (objectsList.length > 1) {
    // sort objects alphabetically
    objectsList.sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.size > b.size) ? 1 : -1) : -1 )
    displayOptions.push(
      {"header": "Objects",
        "items": objectsList
      }
    );
  }

  return displayOptions;
}

function DisplayItems({displayItems, containsElementTypes, modelSettings, setModelSettings}) {
  return (
    displayItems.map((item) => {
      // if (containsElementTypes.includes(item.settings.type) || containsElementTypes.length === 0) {
        // if (containsElementTypes.length === 0) {
        return (
          <Dropdown.Item
            varient="light"
            onClick={(e) => 
              setModelSettings({...modelSettings, "type": item.settings.type, "related": item.settings.related})
            }
          >{item.label.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</Dropdown.Item>
        );
      // }
      // return null;
    })
  );
}

function getVisualizationList({geoJson}) {
  let visualizationList = [];

  geoJson.features.forEach((feature) => {
    feature.properties.visualization.forEach((property) => {
      visualizationList.push(property.name);
    });
  });

  visualizationList = new Set(visualizationList);
  return visualizationList;
}

function getVisualizationPropertyRange({geoJson, modelSettings}) {
  if (!modelSettings.visPropertyType || !modelSettings.visProperty) {
    return null;
  }
  let valueList = [];
  geoJson.features.forEach((feature) => {
    if (feature && feature.properties.visualization[modelSettings['visPropertyType']]) {
      if (feature.properties.visualization[modelSettings['visPropertyType']]) {
        feature.properties.visualization[modelSettings['visPropertyType']].forEach((property) => {
          if (property.name == [modelSettings['visProperty']] && !valueList.includes(property.value)) {
            valueList.push(property.value);
          }
        });
      }
    }
  });
  return valueList;
}