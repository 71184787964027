import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useRealmApp } from "../RealmApp";
import styled from "@emotion/styled";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as SearchIcon } from "../assets/img/search.svg";
import background from "../assets/img/navbar_background.jpg"


export default function SearchBar() {
  const app = useRealmApp();
  const [searchString, setSearchString] = React.useState("");
  let history = useHistory();
  return (
    <Navbar style={{ backgroundImage: `url(${background})` }} expand="lg">
      <Brand />
      <SearchForm 
        history={history}
        searchString={searchString} 
        setSearchString={setSearchString} 
      />
      <UserDropdown 
        history={history}
        currentUser={app.currentUser} 
        handleLogout={() => app.logOut()}
      />
    </Navbar>
  );
}

function Brand() {
  return (
    <Link to="/">
      <Navbar.Brand>
        <BrandText>Precedent</BrandText>
      </Navbar.Brand>
    </Link>
  );
}

function SearchForm({
  history,
  searchString, 
  setSearchString
}) {
  return (
    <InputGroup 
    className="w-50 mx-auto">
      <Form.Control
        className="border-0"
        placeholder=""
        aria-label="Search"
        aria-describedby="basic-addon2"
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch({
              history,
              searchString
            })
            e.preventDefault();
          }
        }}
      />
      <InputGroup.Append>
        <SearchButton 
          onClick={() => (
            handleSearch({
              history,
              searchString
            })
          )}
        >
          <SearchIcon fill='white' outline='white'/>
        </SearchButton>
      </InputGroup.Append>
    </InputGroup>
  );
}

function UserDropdown({
  history,
  currentUser, 
  handleLogout
}) {
  return (
    <NavDropdown 
      alignRight 
      title={currentUser.customData.custom_data.firstName ? <BrandText>Hi, {currentUser.customData.custom_data.firstName}</BrandText> : <BrandText>{currentUser.customData.data.email}</BrandText>} 
      as={Dropdown}
    >
        <NavDropdown.Item onClick={() => history.push("/profile")}>Edit Profile</NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.push("/upload")}>Upload Model</NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.push("/support")}>Get Support</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item 
        href="#"
        onClick={handleLogout}
      >
      Log Out</NavDropdown.Item>
    </NavDropdown>
  );
}

function handleSearch({
  history,
  searchString
}) {
  history.push(`/search/${searchString}`);
}

const SearchButton = styled(Button)`
  background-color: #215373;
  border: 0px;
`;

const BrandText = styled.span`
  color: #FFFFFF;
  font-family: "Bitter", serif;
  font-weight: 500;
`;