import React from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import RadarChartGraph from "../graphs/RadarChart";

export default function RadarChartCard({groupName, adjacencyDefinitionObject, label=''}) {
  // The adjacencyDefinitionObject is they object type in the database that defines whether or not two spaces are adjacent
  const app = useRealmApp();
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    // app.currentUser.functions.getStatsGroupAdjacenciesByDoor(groupId)
    // getAdjacencies(app, groupId, adjacencyDefinitionObject)
    // The group name is based on the text value of the NameTag annotation
    app.currentUser.functions.getRelatedSpaceAnnotationsByEntityType(
      'entitySpace', 'NameTag', groupName, adjacencyDefinitionObject
    )
    .then((response) => {
      setResponseData(response);
    })
  }, [app.currentUser.functions, groupName, adjacencyDefinitionObject]);

  if (responseData) {
    
    return (
      <Card className="mt-1 pb-2 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">{label}</Card.Header>
        <RadarChartGraph
          data={responseData}
        />
        <Description adjacencyDefinitionObject={adjacencyDefinitionObject} />
      </Card>
    );
  } else {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">{label}</Card.Header>
      </Card>
    );
  }
}

async function getAdjacencies(app, groupId, adjacencyDefinitionObject) {
  // Calls different functions based on whether the user wants to define adjacencies based on shared walls or shared doors
  // The adjacencyDefinitionObject is they object type in the database that defines whether or not two spaces are adjacent
  let response = null;
  if (adjacencyDefinitionObject === "Door") {
    response = await app.currentUser.functions.getStatsGroupAdjacenciesByDoor(groupId);
  }

  if (adjacencyDefinitionObject === "Wall") {
    response = await app.currentUser.functions.getStatsGroupAdjacenciesByWall(groupId);
  }

  return response;
}

function Description({adjacencyDefinitionObject}) {
  if (adjacencyDefinitionObject === "Door") {
    return (
      <>
        <p className="mb-1">The above chart shows the most common space groups that have a door between them. 
          Each axis of the chart shows the percentage of times a space in this group shares a door with another group</p>
      </>
    );
  }

  if (adjacencyDefinitionObject === "Wall") {
    return (
      <>
        <p className="mb-1">The above chart shows the most space groups that share a wall with one another.
          Each axis of the chart shows the percentage of times a space in this group shares a wall another group</p>
      </>
    );
  }

  return null;
}