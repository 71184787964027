import React from 'react';
import Card from 'react-bootstrap/Card';
import { useRealmApp } from "../../RealmApp";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from 'react-bootstrap';
import SpaceRender from '../viewer/SpaceRender';
import LoadingSmall from '../LoadingSmall';

export default function GeoJsonViewerCard({objectId, dropdownGroups=[]}) {
  const app = useRealmApp();

  const [modelSettings, setModelSettings] = React.useState({'type': 'Space'});
  const [responseData, setResponseData] = React.useState("");

  console.log("Viewer ObjectId:", objectId);
  console.log("Model Settings:", modelSettings);

  React.useEffect(() => {
    setResponseData("");
    console.log(modelSettings);
    app.currentUser.functions.getFeatureCollectionSpace(objectId, modelSettings)
    .then((response) => {
      setResponseData(response);
      // console.log(response);
    })
  }, [objectId, modelSettings, app.currentUser.functions]);

  if (responseData) {
    
    if (!responseData[0]) {
      return (
        <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
          <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
            Model
            <ToggleButtonExample 
              dropdownGroups={dropdownGroups}
              modelSettings={modelSettings}
              setModelSettings={setModelSettings}
            />
          </Card.Header>
          <Card.Body>
            Oops... We couldn't find any {modelSettings.type.toLowerCase()}s in the model.
          </Card.Body>
        </Card>
      );
    }
    
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
        <ToggleButtonExample 
          dropdownGroups={dropdownGroups}
          modelSettings={modelSettings}
          setModelSettings={setModelSettings}
        />
      </Card.Header>
        <SpaceRender
          geoJson={responseData[0]}
          objectId={objectId}
          modelSettings={modelSettings}
        />
      </Card>
    );
  } else {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
        <ToggleButtonExample 
          dropdownGroups={dropdownGroups}
          modelSettings={modelSettings}
          setModelSettings={setModelSettings}
        />
      </Card.Header>
      <LoadingSmall />
      </Card>
    );
  }
}

function ToggleButtonExample({dropdownGroups, modelSettings, setModelSettings}) {

  const displayOptions = [
    {
      "header": "Space",
      "items": [
        {"label": "Default", "settings": {"type": "Space", "related": false, 'relationship': null}}
      ]
    }
  ];

  displayOptions.push(...dropdownGroups);

  displayOptions.push(
    {"header": "Related Spaces",
    "items": [
      {"label": "Shared Doors", "settings": {"type": "Door", "related": true, 'relationship': 'boundedBy'}},
      {"label": "Shared Walls", "settings": {"type": "Wall", "related": true, 'relationship': 'boundedBy'}}
    ]}
  );

  return (
    <ButtonGroup className="float-right">
      <DropdownButton
        className="border-0"
        size="sm"
        variant="light"
        title="Show"
      >
        {displayOptions.map((group) => (
          <>
            <Dropdown.Header className="text-muted">{group.header}</Dropdown.Header>
            <DisplayItems 
              displayItems={group.items}
              modelSettings={modelSettings} 
              setModelSettings={setModelSettings} 
            />
          </>
        ))}
      </DropdownButton>
    </ButtonGroup>
  );
}

function DisplayItems({displayItems, modelSettings, setModelSettings}) {
  // sort data alphabetically
  displayItems.sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.size > b.size) ? 1 : -1) : -1 )

  return (
    displayItems.map((item) => {
      return (
        <Dropdown.Item
          varient="light"
          onClick={(e) => 
            setModelSettings({...modelSettings, ...item.settings})
          }
        >{item.label.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</Dropdown.Item>
      );
    })
  );
}